var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-min-content xl:w-full sm:w-full px-0"},[(_vm.loading)?_c('div',{staticClass:"spinner mx-auto"},[_c('a-spin',[_c('a-icon',{staticStyle:{"font-size":"64px"},attrs:{"slot":"indicator","type":"loading","spin":""},slot:"indicator"})],1)],1):_vm._e(),_c('Accordion',{attrs:{"header":"De la función de atención y resolución de quejas"}},[(!_vm.loading)?_c('Products_Reasons',{attrs:{"config":{
        annual_report: _vm.annual_report,
        report_type: _vm.report_type,
        refProductsFiles: 1,
        refReasonsFiles: 2,
      },"data":_vm.reasons_products_data,"saving":_vm.toSave,"loading":_vm.loading,"lectureMode":_vm.lectureMode},on:{"products":function($event){_vm.productsSelected = $event},"reasons":function($event){_vm.reasonsSelected = $event},"on-error":_vm.onErrorProductReasons}}):_vm._e(),_c('h2',{staticClass:"my-6 text-left text-xl text-gray-900 font-bold"},[_vm._v(" Reporte de quejas ")]),_c('div',{staticClass:"flex flex-col gap-6"},[_c('Accordion',{attrs:{"header":"Quejas terminadas por rectificación "}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.rectificationLoad,
            section: _vm.rectificationSection,
            productsValue: [],
            annexesLoad: _vm.annexes.rectificationAnnexesLoad,
          },"config":{
            numInputLabel: 'Quejas terminadas por rectificación',
            section: 'rectification',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Quejas terminadas por desistimiento"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.withdrawalLoad,
            section: _vm.withdrawalSection,
            productsValue: [],
            annexesLoad: _vm.annexes.withdrawalAnnexesLoad,
          },"config":{
            numInputLabel: 'Quejas terminadas por desistimiento',
            section: 'withdrawal',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Quejas terminadas por inadmisión"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.inadmissionLoad,
            section: _vm.inadmissionSection,
            productsValue: [],
            annexesLoad: _vm.annexes.inadmissionAnnexesLoad,
          },"config":{
            numInputLabel: 'Quejas terminadas por inadmisión',
            section: 'inadmission',
          }},on:{"product":_vm.getSectionValues}})],1),_c('Accordion',{attrs:{"header":"Quejas terminadas por decisión emitida por DCF"}},[_c('Products',{attrs:{"lectureMode":_vm.lectureMode,"values":{
            selectOptions: {
              products: _vm.productsSelected,
              reasons: _vm.reasonsSelected,
            },
            selectedOptions: _vm.multiSelectLoad.emitionLoad,
            section: _vm.emitionSection,
            productsValue: [],
            annexesLoad: _vm.annexes.emitionAnnexesLoad,
          },"config":{
            numInputLabel: 'Quejas terminadas por decisión emitida por DCF',
            section: 'emition',
          }},on:{"product":_vm.getSectionValues}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }